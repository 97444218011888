import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ToastService} from './app-toast.service';
import {TranslationService} from '../utils/translate.service';
import {of} from 'rxjs';
import {TokenStorageService} from './token-storage.service';

@Injectable({providedIn: 'root'})
export class RequestsService {

  constructor(
    private http: HttpClient,
    private toast: ToastService,
    private translate: TranslationService,
    private tokenStorage: TokenStorageService,
  ) {
  }

  getApiUrl(url) {
    return `${environment.apiUrl}${url}`;
  }

  forgotPassword(data) {
    return this.http.put(this.getApiUrl('/user/forgot'), data);
  }

  // get_clients(data) {
  //   const options = new HttpHeaders({Authorization: this.loggedUser.getToken()});
  //   options['search'] = data;
  //   return this.http.get(this.getApiUrl('/api/admin/client_users'), options);
  // }

  get(url, data, token = null) {
    return this.sendRequest('GET', url, data, token);
  }

  put(url, data, token = null) {
    return this.sendRequest('PUT', url, data, token);
  }

  post(url, data, token = null) {
    return this.sendRequest('POST', url, data, token);
  }

  patch(url, data, token = null) {
    return this.sendRequest('PATCH', url, data, token);
  }

  delete(url, data, token = null) {
    return this.sendRequest('DELETE', url, data, token);
  }

  sendRequest(method, url, data, token = null) {
    const options: any = {};

    if (data) {
      if (method === 'GET' || method === 'DELETE') {
        options.params = data;
      }
    }

    if (token) {
      options.headers = new HttpHeaders({Authorization: this.tokenStorage.getToken()});
    }

    // options.headers = new Headers({Authorization: this.loggedUser.getToken()});

    if (method === 'GET') {
      return this.http.get(this.getApiUrl(url), options);
    }

    if (method === 'PUT') {
      return this.http.put(this.getApiUrl(url), data, options);
    }

    if (method === 'POST') {
      return this.http.post(this.getApiUrl(url), data, options);
    }

    if (method === 'PATCH') {
      return this.http.patch(this.getApiUrl(url), data, options);
    }

    if (method === 'DELETE') {
      return this.http.delete(this.getApiUrl(url), options);
    }

    return of(null);
  }

  postImage(img) {
    return this.http.post(this.getApiUrl('/upl/profile-upload'), {
      upload: img,
      token: this.tokenStorage.getToken()
    });
  }

  handleError(err) {
    if (err && 'error' in err && 'message' in err.error) {
      const message = err.error.message;
      this.toast.showInfoToast(this.translate.instant(message));
    } else {
      this.toast.showError();
    }
  }
}
