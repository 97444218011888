import {Component, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {RequestsService} from '../services/requests.service';
import {LoggedUserService} from '../services/logged-user.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MediatorService} from '../services/mediator.service';
import {Router} from '@angular/router';
import {WsService} from '../utils/ws.service';

@Component({
  selector: 'app-code-generator',
  templateUrl: './code-generator.component.html',
  styleUrls: ['./code-generator.component.scss']
})
export class CodeGeneratorComponent implements OnDestroy {
  code = new FormControl({value: null, disabled: true});

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private ws: WsService,
    private router: Router,
    private snackbar: MatSnackBar,
    public mediator: MediatorService,
    private requests: RequestsService,
    private translate: TranslateService,
    public loggedUser: LoggedUserService,
  ) {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onGenerateCode() {
    this.requests.get('/api/code', {}, this.loggedUser.getToken()).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
      this.code.setValue(resp.code);
      this.snackbar.open(this.translate.instant('Code generated'), null, {duration: 1000});
    }, err => {
      this.snackbar.open(this.translate.instant('Code not generated'), null, {duration: 1000});
    });
  }

  onLogout() {
    this.loggedUser.requestLogout().pipe(takeUntil(this.destroy$))
      .subscribe(res => {
        this.loggedUser.logout(res);
        this.router.navigate(['']);
      }, error => console.log(error));
  }
}
