import {Component, OnDestroy, OnInit} from '@angular/core';
import {LoggedUserService} from '../../services/logged-user.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MediatorService} from '../../services/mediator.service';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('fadeInOpacity', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 0.6, offset: 1})
          ])
        ))
    ])
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject();

  constructor(
    public mediator: MediatorService,
    public loggedUser: LoggedUserService
  ) {
  }

  ngOnInit() {
    this.loggedUser.loginData = {username: '', password: ''};

    this.mediator.loginStepChanges$.pipe(takeUntil(this.destroy$)).subscribe(data => {});
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
