import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class TokenStorageService {
  private token: string;
  private tokenName = 'token';


  constructor(
  ) {
    this.token = localStorage.getItem(this.tokenName);
  }

  getToken() {
    return this.token;
  }

  setToken(token) {
    this.token = token;
    localStorage.setItem(this.tokenName, token);
  }

  removeToken() {
    delete this.token;
    localStorage.removeItem(this.tokenName);
  }
}
