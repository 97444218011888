<div fxLayout fxLayoutAlign="center center" style="min-height: 480px">
  <div class="w-75"
       fxLayout fxLayoutAlign="space-between center" fxLayoutGap="2rem"
       fxLayout.lt-md="column" ngClass.lt-lg="w-100">
    <div fxLayout="column" fxFlex="35%" style="min-width: 400px"
         fxFlex.lt-md="50%" fxFlexOrder.lt-md="2">
      <div class="mb-3">
        <ng-content select="[slot=text]"></ng-content>
      </div>
      <div class="form-component mb-5">
        <ng-content select="[slot=input]"></ng-content>
      </div>
      <div class="text-center">
        <ng-content select="[slot=button]"></ng-content>
      </div>
    </div>
    <div fxLayout fxFlexOrder.lt-md="1">
      <ng-content select="[slot=image]"></ng-content>
    </div>
  </div>
</div>
