import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggedUserService} from '../services/logged-user.service';
import {LookupService} from '../utils/lookup.service';

@Injectable({providedIn: 'root'})
export class EntryGuardService implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private lookup: LookupService,
    private loggedUser: LoggedUserService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Promise((resolve) => {
      this.loggedUser.requestLoginStatus().subscribe((r: any) => {
        this.lookup.userRoles = r.roles;
        this.loggedUser.login(r);
        resolve(true);
      }, err => {
        this.loggedUser.logout(err);
        resolve(false);
        this.router.navigate(['']);
      });
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
