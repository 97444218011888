import {NgModule} from '@angular/core';
import {AuthModule} from './auth/auth.module';
import {SharedModule} from './shared/shared.module';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {LoaderDirective} from './directives/loader.directive';

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import { EntryPageComponent } from './entry-page/entry-page.component';
import { CodeEntryComponent } from './code-entry/code-entry.component';
import { CodeGeneratorComponent } from './code-generator/code-generator.component';
import {LoaderComponent} from './shared/components/loaders/loader/loader.component';
import { ConversationRoomComponent } from './conversation-room/conversation-room.component';
import { MediaConfigurationComponent } from './conversation-room/media-configuration/media-configuration.component';

@NgModule({
  declarations: [
    LoaderDirective,

    AppComponent,
    HomeComponent,
    LoaderComponent,
    EntryPageComponent,
    CodeEntryComponent,
    CodeGeneratorComponent,
    ConversationRoomComponent,
    MediaConfigurationComponent,
  ],
  imports: [
    AuthModule,
    SharedModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
