function binaryDecomposer(value, source) {
  const values = [];
  for (const num in source) {
    // tslint:disable-next-line:no-bitwise
    if (source.hasOwnProperty(num) && !!(+num & value)) {
      values.push(num);
    }
  }
  return values;
}

function binaryAggregator(values: number[]) {
  // tslint:disable-next-line:no-bitwise
  const reducer = (accumulator, currentValue) => accumulator | currentValue;
  return values.length > 0 ? [...values].reduce(reducer) : 0;
}

function setContrastColor(color) {
  if (color.slice(0, 1) === '#') {
    color = color.slice(1);
  }

  if (color.length === 3) {
    color = `${color}${color[0]}${color[0]}${color[0]}`;
  }

  const r = parseInt(color.substr(0, 2), 16);
  const g = parseInt(color.substr(2, 2), 16);
  const b = parseInt(color.substr(4, 2), 16);

  // Get YIQ ratio
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

  return (yiq >= 128) ? '#000' : '#fff';
}

function hexToRgba(hex, opacity = 1) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    // tslint:disable-next-line:no-bitwise
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  }
  throw new Error('Bad Hex');
}

export {binaryDecomposer, binaryAggregator, setContrastColor, hexToRgba};

