<div class="wrapper" fxLayout="column">
  <img class="not-found-image" src="/assets/img/404.png" alt="404-page" height="600px" width="600px">
  <p class="not-found-text text-center mb-5">
    <!--{{'pageNotFound' | translate}}.-->
    <span class="color-primary">{{'Auf dieser Seite ist niemand.' | translate}}</span> <br>
    {{'Bitte überprüfen Sie, ob Sie am richtigen Ort sind' | translate}}
    <!--<span class="not-found-link" [routerLink]="['']"> {{'goBackHome' | translate}}</span>-->
  </p>
  <button class="button-primary" mat-flat-button color="primary" [routerLink]="['']">{{'Rückkehr' | translate}}</button>
</div>
