import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LoaderService {
  loaderPresets = ['defaultPreset', 'mapPreset', 'cardPreset', 'tablePreset'];
  loading$ = new Subject<boolean>();

  constructor() { }
}
