import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {LoginGuard} from './auth/login-guard.service';
import {EntryGuardService} from './guards/entry-guard.service';
import {Step2GuardService} from './guards/step-2-guard.service';
import {ResetPasswordGuardService} from './guards/reset-password-guard.service';
import {ConversationGuardService} from './conversation-room/conversation-guard.service';

import {HomeComponent} from './home/home.component';
import {LoginComponent} from './auth/login/login.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {CodeEntryComponent} from './code-entry/code-entry.component';
import {CodeGeneratorComponent} from './code-generator/code-generator.component';
import {ResetPasswordComponent} from './auth/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {ConversationRoomComponent} from './conversation-room/conversation-room.component';
import {LoginFirstStepComponent} from './auth/login/login-first-step/login-first-step.component';
import {LoginSecondStepComponent} from './auth/login/login-second-step/login-second-step.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {breadcrumb: 'Home', path: '', linkCode: '0.1'},
    children: [
      {path: '', redirectTo: 'enter-code', pathMatch: 'prefix'},
      {
        path: 'generate-code',
        canActivate: [EntryGuardService],
        component: CodeGeneratorComponent
      },
      {path: 'enter-code', component: CodeEntryComponent},
      {
        path: 'conversation-room',
        component: ConversationRoomComponent,
        canActivate: [ConversationGuardService],
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    children: [
      {path: '', redirectTo: 'first-step', pathMatch: 'prefix'},
      {path: 'first-step', component: LoginFirstStepComponent},
      {path: 'second-step', component: LoginSecondStepComponent, canActivate: [Step2GuardService]},
      {path: 'forgot-password', component: ForgotPasswordComponent},
      {path: 'reset-password/:hash', component: ResetPasswordComponent, canActivate: [ResetPasswordGuardService]},
    ]
  },
  {path: 'not-found', component: NotFoundComponent, data: {message: 'Page not found!'}},
  {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
