import {LoggedUserService} from '../services/logged-user.service';
import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {TokenStorageService} from '../services/token-storage.service';

const WS_HOST = window.location.host.indexOf('localhost') === -1 ? window.location.host : 'webrtc.digitalcube.rs';
let PROTOCOL = window.location.protocol.split(':')[0] === 'https' ? 'ws' : 'wss';
PROTOCOL = window.location.host.split(':')[0] === 'localhost' ? PROTOCOL : 'wss';

@Injectable()
export class WsService {
  ws;
  wsConnectTimeout;
  WS_URL = PROTOCOL + '://' + WS_HOST + '/ws';
  tryCounter = 0;

  canTransferMessages = false;

  messageEmit$: Subject<any> = new Subject();
  errorRecieve$: Subject<any> = new Subject();
  connectionStatusChange$: Subject<any> = new Subject();

  constructor(
    private tokenStorage: TokenStorageService,
  ) {
  }

  connect() {
    /*
      initialize web socket connection with all settings if it is not already exists. User has to be logged in, e.g. token must exists
    */

    if (this.ws && (this.ws.readyState === this.ws.CONNECTING || this.ws.readyState === this.ws.OPEN)) {
      return;
    }

    const token = this.tokenStorage.getToken();

    // if (!token) {
    //   this.reconnect();
    //   return;
    // }

    // this.ws = new WebSocket(this.WS_URL, ['token', token]);
    this.ws = new WebSocket('wss://webrtc.digitalcube.rs/ws');

    this.ws.onopen = (data) => {
      this.tryCounter = 0;
      this.canTransferMessages = true;
      this.connectionStatusChange$.next(true);
      if (this.wsConnectTimeout) {
        clearTimeout(this.wsConnectTimeout);
      }
    };
    this.ws.onclose = (data) => {
      console.log('on close', data);
      this.canTransferMessages = false;
      this.connectionStatusChange$.next(false);
      // this.reconnect();
    };
    this.ws.onerror = (data) => {
      console.log('on error', data);
      this.disconnect();
    };
    this.ws.onmessage = (msg) => {
      const data = JSON.parse(msg.data);
      if ('cmd' in data && data.cmd === 'reqError') {
        this.errorRecieve$.next(data.payload.msg);
      } else {
        this.messageEmit$.next(data);
      }
    };
  }

  disconnect() {
    this.ws.close(1000, 'Close on error');
  }

  reconnect() {
    /* initialize web-socket connection after increasing number of seconds (minutes) */

    console.log('RECONNECT', 1000 * (this.tryCounter || 1), 'ms');

    if (this.wsConnectTimeout) {
      clearTimeout(this.wsConnectTimeout);
    }
    this.wsConnectTimeout = setTimeout(() => {
      if (!this.ws || this.ws.readyState === this.ws.CLOSED) {
        console.log('Try to connect to ws');
        this.connect();
      } else {
        this.reconnect();
      }
    }, 1000 * (this.tryCounter || 1));

    this.tryCounter++;
  }

  sendMessage(msg: string) {
    this.ws.send(msg);
  }
}

