import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {MaterialModule} from './material.module';
import {TranslateModule} from '@ngx-translate/core';
import {HttpClientModule} from '@angular/common/http';
import {FlexLayoutModule} from '@angular/flex-layout';
import {BrowserModule} from '@angular/platform-browser';
import {ModuleWithProviders, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {WsService} from '../utils/ws.service';
import {LookupService} from '../utils/lookup.service';
import {ToastService} from '../services/app-toast.service';
import {MediatorService} from '../services/mediator.service';
import {RequestsService} from '../services/requests.service';
import {TranslationService} from '../utils/translate.service';
import {LoggedUserService} from '../services/logged-user.service';
import {RouterGuardService} from '../guards/router-guard.service';
import {TokenStorageService} from '../services/token-storage.service';
import {MatIconRegistryService} from '../utils/mat-icon-registry.service';
import {ResetPasswordGuardService} from '../guards/reset-password-guard.service';

import {AutofocusDirective} from '../directives/autofocus.directive';
import {DynamicColorDirective} from '../directives/dynamic-color.directive';

import {NotFoundComponent} from '../not-found/not-found.component';
import {PlaceholderComponent} from './components/placeholder/placeholder.component';
import {CustomSnackbarComponent} from './components/snackbars/custom-snackbar/custom-snackbar.component';
import {ConfirmationDialogComponent} from './components/dialogs/confirmation-dialog/confirmation-dialog.component';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    BrowserModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot()
  ],
  declarations: [
    AutofocusDirective,
    DynamicColorDirective,

    NotFoundComponent,
    PlaceholderComponent,
    CustomSnackbarComponent,
    ConfirmationDialogComponent,
  ],
  providers: [],
  exports: [
    FormsModule,
    CommonModule,
    RouterModule,
    BrowserModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,

    TranslateModule,

    AutofocusDirective,
    DynamicColorDirective,

    NotFoundComponent,
    CustomSnackbarComponent,
    PlaceholderComponent,
    ConfirmationDialogComponent,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        WsService,
        ToastService,
        LookupService,
        RequestsService,
        MediatorService,
        LoggedUserService,
        TranslationService,
        RouterGuardService,
        TokenStorageService,
        MatIconRegistryService,
        ResetPasswordGuardService,
      ]
    };
  }
}
