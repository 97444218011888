<div class="dialog" >
  <div class="dialog__header">
    <div class="dialog__header__title">{{'passwordChange' | translate}}</div>
  </div>
  <div class="dialog__body">
    <div class="form-wrapper d-flex align-start justify-content-center">
      <div class="form-content d-flex flex-column" [formGroup]="changePasswordForm">
        <section class="form-body">
          <section class="form-row d-flex flex-column flex-nowrap">
            <mat-form-field class="form-control" floatLabel="always">
              <mat-label>{{'oldPassword' | translate}}</mat-label>
              <input matInput formControlName="oldPassword" spellcheck="false" [type]="isVisible ? 'text' : 'password'">
              <mat-icon class="icon icon--hover" matSuffix (click)="isVisible = !isVisible">
                {{isVisible ? 'visibility' : 'visibility_off'}}
              </mat-icon>
              <mat-error *ngIf="changePasswordForm.invalid">{{'enterPassword' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field class="form-control" floatLabel="always">
              <mat-label>{{'newPassword' | translate}}</mat-label>
              <input matInput formControlName="newPassword" spellcheck="false" [type]="isVisible ? 'text' : 'password'">
              <mat-icon class="icon icon--hover" matSuffix (click)="isVisible = !isVisible">
                {{isVisible ? 'visibility' : 'visibility_off'}}
              </mat-icon>
              <mat-error *ngIf="changePasswordForm.invalid">{{'enterPassword' | translate}}</mat-error>
            </mat-form-field>
          </section>
        </section>
      </div>
    </div>
  </div>
  <div class="dialog__footer">
    <div class="dialog__actions">
      <button class="dialog__actions__button" mat-button type="reset" (click)="cancel()">{{'cancel' | translate}}</button>
      <button class="dialog__actions__button" (click)="confirm()"
              mat-flat-button color="primary" [disabled]="!changePasswordForm.valid">
        {{'submit' | translate}}
      </button>
    </div>
  </div>
</div>
