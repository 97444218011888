import {Component, OnDestroy, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {fromEvent, Subject} from 'rxjs';
import {MatIconRegistryService} from './utils/mat-icon-registry.service';
import {ToastService} from './services/app-toast.service';
import {MediatorService} from './services/mediator.service';
import {ThemeService} from './services/theme.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {OverlayContainer} from '@angular/cdk/overlay';
import {LookupService} from './utils/lookup.service';
import {TranslationService} from './utils/translate.service';
import {RequestsService} from './services/requests.service';
import {MediaObserver} from '@angular/flex-layout';
import {LoggedUserService} from './services/logged-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'telmekom-webrtc';

  environment = environment;
  destroy$: Subject<boolean> = new Subject();

  constructor(
    public toast: ToastService,
    public lookup: LookupService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public themeService: ThemeService,
    public mediaObserver: MediaObserver,
    public translate: TranslationService,
    public loggedUser: LoggedUserService,
    private overlayContainer: OverlayContainer,
    public iconRegistry: MatIconRegistryService
  ) {
    this.translate.setLang(localStorage.getItem('lang') ? localStorage.getItem('lang') : 'EN');

    fromEvent(window, 'resize').pipe(debounceTime(100), takeUntil(this.destroy$)).subscribe(() => {
      this.mediator.vh = window.innerHeight * 0.01;

      if (this.mediator.screenWidth > environment.minMobWidth) {
        this.mediator.contentHeight = this.mediator.vh * 100 - this.mediator.headerHeight;
      }
      if (this.mediator.screenWidth < environment.minMobWidth) {
        this.mediator.contentHeight = this.mediator.vh * 100 - this.mediator.mobileHeaderHeight;
      }
    });
  }

  ngOnInit() {
    const chosenTheme = this.lookup.themes.find(theme => theme.themeClass === 'custom-theme');

    this.themeService.theme$.pipe(takeUntil(this.destroy$)).subscribe(activeTheme => {
      this.mediator.activeTheme = this.lookup.themes.find(theme => theme.themeClass === activeTheme);

      const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
      const themeClassesToRemove = Array.from(this.lookup.themes.map(theme => theme.themeClass))
        .filter((item: string) => item.includes('-theme'));
      if (themeClassesToRemove.length) {
        overlayContainerClasses.remove(...themeClassesToRemove);
      }
      overlayContainerClasses.add(this.mediator.activeTheme.themeClass);
    });

    this.themeService.setTheme(chosenTheme.themeClass);

    this.mediaObserver.asObservable().pipe(takeUntil(this.destroy$)).subscribe((change) => {
      this.mediator.isHandset = change[0].mqAlias === 'xs';
      this.mediator.isHandsetLandscape = change[1].mqAlias === 'lt-md';
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
