<div style="height: 750px" fxLayout="column" fxLayoutAlign="space-between">
  <div class="mb-3">
    <div class="mb-3" fxLayout fxLayoutAlign="space-between center">
      <span class="title">{{'Einstellungen' | translate}}</span>
      <button mat-icon-button (click)="dialogRef.close()">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="dialog-form">
      <div class="mb-3" fxLayout="column">
        <label>{{'Lautsprecher' | translate}}</label>
        <mat-form-field>
          <mat-select [formControl]="audioOutput">
            <mat-option *ngFor="let output of audioOutputs" [value]="output.deviceId"
                        (click)="onDeviceChange('audioOutput', output)">
              {{output.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mb-3" fxLayout="column">
        <label>{{'Mikrofon' | translate}}</label>
        <mat-form-field>
          <mat-select [formControl]="microphone">
            <mat-option *ngFor="let input of audioInputs" [value]="input.deviceId"
                        (click)="onDeviceChange('microphone', input)">
              {{input.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mb-3" fxLayout="column">
        <label>{{'Kamera' | translate}}</label>
        <mat-form-field>
          <mat-select [formControl]="videoCamera" #videoSelection="matSelect">
            <mat-option *ngFor="let input of videoInputs" [value]="input.deviceId"
                        (click)="onDeviceChange('videoInput', input)">
              {{input.label}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="text-center" style="height: 286px; background-color: rgba(0,0,0,0.7)">
        <video style="min-height: 287px; height: 287px" #configurationVideo
               [srcObject]="stream" [muted]="'muted'" playsinline autoplay>
        </video>
      </div>
    </div>
  </div>
  <div class="text-center">
    <button class="button-primary w-100" mat-flat-button
            (click)="onConfirm()" color="primary">
      {{'Bestätigen' | translate}}
    </button>
  </div>
</div>
