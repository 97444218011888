import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-media-configuration',
  templateUrl: './media-configuration.component.html',
  styleUrls: ['./media-configuration.component.scss']
})
export class MediaConfigurationComponent implements OnInit, OnDestroy {
  audioInputs = [];
  videoInputs = [];
  audioOutputs = [];

  @ViewChild('configurationVideo') video: any;

  stream: MediaStream;
  hasSelectionChanged = false;

  microphone = new FormControl();
  videoCamera = new FormControl();
  audioOutput = new FormControl();

  constraints: MediaStreamConstraints = {video: true, audio: true};

  constructor(
    public dialogRef: MatDialogRef<MediaConfigurationComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  ngOnInit(): void {
    navigator.mediaDevices.enumerateDevices()
      .then((mediaDevices) => this.onDevices(mediaDevices))
      .catch(err => alert(err));
  }

  ngOnDestroy(): void {
    this.constraints = null;

    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
  }

  onDevices(mediaDevices: MediaDeviceInfo[] | InputDeviceInfo[]) {
    mediaDevices.forEach((mediaDevice: MediaDeviceInfo | InputDeviceInfo) => {
      switch (mediaDevice.kind) {
        case 'audioinput': {
          this.audioInputs.push(mediaDevice);
          break;
        }
        case 'videoinput': {
          this.videoInputs.push(mediaDevice);
          break;
        }
        case 'audiooutput': {
          this.audioOutputs.push(mediaDevice);
          break;
        }
      }
    });

    this.constraints = {...this.data.currentConstraints};
    this.setStream(this.constraints);
    this.microphone.setValue(this.audioInputs[0] ? this.audioInputs[0].deviceId : []);
    this.videoCamera.setValue(this.videoInputs[0] ? this.videoInputs[0].deviceId : []);
    this.audioOutput.setValue(this.audioOutputs[0] ? this.audioOutputs[0].deviceId : []);
  }

  onDeviceChange(device: string, value) {
    this.updateConstraints(device, value);
    this.setStream(this.constraints);
  }

  whichCamera(track: MediaStreamTrack) {
    return track.getSettings().facingMode;
  }

  onConfirm() {
    const videoId =  this.stream.getVideoTracks()[0].getSettings().deviceId;
    const micId =  this.stream.getAudioTracks()[0].getSettings().deviceId;
    this.constraints = null;
    this.stream.getTracks().forEach(track => track.stop());
    this.stream = null;
    this.dialogRef.close({audio: this.video.nativeElement.sinkId, mic: micId, video: videoId});
  }

  setStream(constraints: MediaStreamConstraints) {
    navigator.mediaDevices.getUserMedia(constraints)
      .then(stream => this.stream = stream)
      .catch(err => alert(err));
  }

  updateConstraints(device: string, value) {
    switch (device) {
      case 'microphone':
        this.constraints.audio = {deviceId: {exact: value.deviceId}};
        break;
      case 'audioOutput':
        this.video.nativeElement.setSinkId(value.deviceId);
        break;
      case 'videoInput': {
        this.constraints.video = {deviceId: {exact: value.deviceId}};
        this.constraints.video.facingMode = 'user';
        break;
      }
      default: {
        this.constraints = {video: true, audio: true};
      }
    }
  }
}
