import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ThemeService {
  private theme = new Subject<string>();
  theme$ = this.theme.asObservable();

  setTheme(theme: string): void {
    this.theme.next(theme);
  }
}
