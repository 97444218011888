<header class="login__header d-flex align-items-start justify-content-between flex-nowrap"
        [class.pointer-disabled]="isFormDisabled">
  <button class="back" (click)="back()" mat-icon-button>
    <mat-icon color="primary">arrow_back</mat-icon>
  </button>
  <div class="login__header-content d-flex flex-column align-items-center" style="margin-top: 0.75rem">
    <p class="login__header__title login__header__title--small w-75">{{'enterNewPassword' | translate}}</p>
  </div>
</header>
<section class="login__content d-flex flex-column"
         [formGroup]="resetPasswordForm" (keyup.enter)="submit()" [class.pointer-disabled]="isFormDisabled">
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{'newPassword' | translate}}</mat-label>
    <input matInput formControlName="newPassword" spellcheck="false" [type]="isVisible ? 'text' : 'password'">
    <mat-icon class="icon icon--hover" matSuffix (click)="isVisible = !isVisible">
      {{isVisible ? 'visibility' : 'visibility_off'}}
    </mat-icon>
    <mat-error *ngIf="resetPasswordForm.invalid">{{'enterPassword' | translate}}</mat-error>
  </mat-form-field>
  <p class="login__section__title login__header__title--small center mb-4">{{'repeatPassword' | translate}}</p>
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{'repeatPassword' | translate}}</mat-label>
    <input matInput formControlName="repeatPassword" spellcheck="false" [type]="isVisible ? 'text' : 'password'">
    <mat-icon class="icon icon--hover" matSuffix (click)="isVisible = !isVisible">
      {{isVisible ? 'visibility' : 'visibility_off'}}
    </mat-icon>
    <mat-error *ngIf="resetPasswordForm.invalid">{{'enterPassword' | translate}}</mat-error>
  </mat-form-field>
</section>
<footer class="login__footer d-flex align-items-center justify-content-end flex-nowrap"
        [class.pointer-disabled]="isFormDisabled">
  <button class="button-login" mat-flat-button color="primary" (click)="submit()">{{'submit' | translate}}</button>
</footer>

<div class="login__info d-flex flex-column align-items-center" *ngIf="isPasswordReset" @fadeIn>
  <div class="login__info__body d-flex justify-content-center align-items-center h-100 w-100">
    <p class="login__info__text center">{{'passwordSuccessfullyReset' | translate}}</p>
  </div>
  <div class="login__info__footer d-flex justify-content-end w-100">
    <button mat-flat-button color="primary" (click)="back()">{{'backToLogin' | translate}}</button>
  </div>
</div>
