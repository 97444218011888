// FOR THIS TO WORK ADD "allowJs": true TO tsconfig.json IN THE "compilerOptions" SECTION
import {Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {tr_map as _en } from './translations/en.ol';
import {tr_map as _it } from './translations/it.ol';
import {tr_map as _de } from './translations/de.ol';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class TranslationService {
  langs = ['EN', 'IT', 'DE'];
  lang = 'EN';
  langChanged = new Subject<string>();

  constructor(private tr: TranslateService) {
    tr.addLangs(this.langs);
    tr.setTranslation('EN', _en);
    tr.setTranslation('IT', _it);
    tr.setTranslation('DE', _de);
    tr.setDefaultLang(this.lang);

    const defaultLang = localStorage.getItem('lang');
    this.lang = defaultLang || 'EN';
    this.tr.use(this.lang);
  }

  getLang() {
    return this.lang;
  }

  getAvailableLangs() {
    return this.tr.getLangs();
  }

  setLang(lang) {
    if (this.langs.indexOf(lang) === -1) {
      alert(`THERE IS NO LANGUAGE: ${lang}`);
      return;
    }

    this.lang = lang;
    this.tr.use(this.lang);
    localStorage.setItem('lang', this.lang);

    this.langChanged.next(this.lang);
  }

  instant(str) {
    return this.tr.instant(str);
  }
}
