<header class="login__header d-flex align-items-start justify-content-between flex-nowrap"
        [class.pointer-disabled]="isFormDisabled">
  <button class="back" (click)="back()" mat-icon-button>
    <mat-icon color="primary">arrow_back</mat-icon>
  </button>
  <div class="login__header-content d-flex flex-column align-items-center">
    <p class="login__header__title w-75">{{'enterNewPassword' | translate}}</p>
  </div>
</header>
<section class="login__content d-flex flex-column"
         [formGroup]="forgotPasswordForm" (keyup.enter)="submit()" [class.pointer-disabled]="isFormDisabled">
  <mat-form-field floatLabel="always">
    <mat-label>{{'oldPassword' | translate}}</mat-label>
    <input matInput formControlName="oldPassword" spellcheck="false" [type]="isVisible ? 'text' : 'password'">
    <mat-icon class="icon icon--hover" matSuffix (click)="isVisible = !isVisible">
      {{isVisible ? 'visibility' : 'visibility_off'}}
    </mat-icon>
    <mat-error *ngIf="forgotPasswordForm.invalid">{{'enterPassword' | translate}}</mat-error>
  </mat-form-field>
  <p class="login__section__title">{{'confirmPassword' | translate}}</p>
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-label>{{'newPassword' | translate}}</mat-label>
    <input matInput formControlName="newPassword" spellcheck="false" [type]="isVisible ? 'text' : 'password'">
    <mat-icon class="icon icon--hover" matSuffix (click)="isVisible = !isVisible">
      {{isVisible ? 'visibility' : 'visibility_off'}}
    </mat-icon>
    <mat-error *ngIf="forgotPasswordForm.invalid">{{'enterPassword' | translate}}</mat-error>
  </mat-form-field>
</section>
<footer class="login__footer d-flex align-items-center justify-content-end flex-nowrap"
        [class.pointer-disabled]="isFormDisabled">
  <button class="button-login" mat-flat-button color="primary" (click)="submit()">{{'change' | translate}}</button>
</footer>
