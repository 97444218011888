import {environment} from '../../environments/environment';
import {BehaviorSubject, Subject} from 'rxjs';
import {Theme} from '../shared/models/theme.model';
import {LINK_DATA} from '../utils/link-data';
import {Injectable} from '@angular/core';

export enum Language {
  EN = 'english',
}

@Injectable({providedIn: 'root'})
export class MediatorService {
  isProgressBarVisible = false;

  loginStepChanges$ = new Subject<any>();

  linkData = [...LINK_DATA];

  activeTheme: Theme;

  headerHeight = 0;
  mobileHeaderHeight = 0;

  isHeaderSticky = false;
  height = window.innerHeight;
  vh = window.innerHeight * 0.01;
  screenWidth = window.screen.width;
  mobileScreenHeight = window.innerHeight;
  contentHeight = this.screenWidth < environment.minMobWidth ? this.vh * 100 - this.mobileHeaderHeight : this.vh * 100 - this.headerHeight;

  isHandset = false;
  isHandsetLandscape = false;

  breadCrumbs = [];
  savedBreadcrumb = {};
  savedRouteParams = {};
  breadcrumbsChanges$ = new BehaviorSubject<any>(null);

  // constructor(@Inject(WINDOW) private window: Window) {
  constructor() {
  }

  openLinkInNewWindow(url) {
    window.open(environment.urlOrigin + url, '_blank');
  }

  getHostname() {
    // return this.window.location.hostname;
  }
}
