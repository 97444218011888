import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {LoggedUserService} from '../services/logged-user.service';
import {LookupService} from '../utils/lookup.service';

@Injectable({providedIn: 'root'})
export class LoginGuard implements CanActivate, CanActivateChild {

  constructor(
    private router: Router,
    private lookup: LookupService,
    private loggedUser: LoggedUserService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Promise((resolve) => {
      if (this.loggedUser.isLogged) {
        resolve(false);
        this.router.navigate(['']);
      } else {
        resolve(true);
      }
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
