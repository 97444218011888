<div class="wrapper">
  <header class="w-100">
    <div class="info-header" fxLayout fxLayoutAlign="space-between start">
      <div fxFlex="100%"></div>
      <div class="pt-3" fxFlex="100%" fxLayout fxLayoutGap="55px" fxLayoutAlign="center">
        <div *ngIf="conversation.isConversationActive">
          <div class="text-center mb-0">
            {{'Gesprächsdauer'}}
          </div>
          <div class="font-weight-bold text-center">
            {{conversation.callDuration}}
          </div>
        </div>
      </div>
      <div fxFlex="100%" fxLayout fxLayoutAlign="end" fxLayoutGap="55px"></div>
    </div>
  </header>
  <section>
    <div class="video-small">
      <!--<img src="/assets/img/profile1.png" alt="call-person" height="auto" width="100%">-->
      <video [srcObject]="conversation.localStream" playsinline autoplay [muted]="'muted'" width="100%"></video>
    </div>
    <div class="video-big" fxLayout fxLayoutAlign="center center">
      <video [srcObject]="conversation.remoteStream" playsinline autoplay></video>
      <!--<img src="/assets/img/profile2.png" alt="call-person" height="100%" width="auto">-->
    </div>
  </section>
  <footer class="w-100">
    <div class="controls" fxLayout fxLayoutAlign="space-between center">
      <div fxFlex="100%"></div>
      <div fxFlex="100%" fxLayout fxLayoutGap="55px" fxLayoutAlign="center">
        <button mat-fab style="background-color: #fff;" (click)="onCameraToggle()">
          <i class="material-icons-outlined" style="color: rgba(0, 0, 0, 0.7)">videocam</i>
        </button>
        <button mat-fab color="warn" (click)="onCallEnd()" *ngIf="conversation.canEndCall">
          <i class="material-icons-outlined" style="color: #fff">call_end</i>
        </button>
      </div>
      <div fxFlex="100%" fxLayout fxLayoutAlign="end" fxLayoutGap="55px">
        <!--<button mat-fab style="background-color: #fff;" (click)="onSettingsChoose()">-->
        <!--  <i class="material-icons-outlined" style="color: rgba(0, 0, 0, 0.7)">settings</i>-->
        <!--</button>-->
      </div>
    </div>
  </footer>
</div>
