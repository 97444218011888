import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ConversationService} from './conversation.service';

@Injectable({providedIn: 'root'})
export class ConversationGuardService implements CanActivate, CanActivateChild {
  constructor(
    private router: Router,
    private conversation: ConversationService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
    return new Promise((resolve) => {
      if (!this.conversation.code || this.conversation.code.length === 0) {
        resolve(false);
        this.router.navigate(['']);
      } else {
        resolve(true);
      }
    });
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}

