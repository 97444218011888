<header class="login__header d-flex align-items-start justify-content-between flex-nowrap"
        [class.pointer-disabled]="isFormDisabled">
  <button class="back" (click)="back()" mat-icon-button>
    <mat-icon color="primary">arrow_back</mat-icon>
  </button>
  <div class="login__header-content d-flex flex-column align-items-center" style="margin-top: 0.75rem">
    <p class="login__header__title w-75 mb-2">{{'enterEmailForgotPass' | translate}}</p>
  </div>
</header>
<section class="login__content d-flex flex-column" (keyup.enter)="submit()" [class.pointer-disabled]="isFormDisabled">
  <div class="email" [formGroup]="forgotPasswordForm" *ngIf="!isEmailProvided">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{'email' | translate}}</mat-label>
      <input matInput formControlName="email" spellcheck="false" appAutofocus>
      <mat-error *ngIf="email.invalid">{{'enterValidEmail' | translate}}</mat-error>
    </mat-form-field>
  </div>
  <div class="new-password" *ngIf="isEmailProvided">
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{'newPassword' | translate}}</mat-label>
      <input matInput formControlName="password" spellcheck="false" [type]="isVisible ? 'text' : 'password'">
      <mat-icon class="icon icon--hover" matSuffix (click)="isVisible = !isVisible">
        {{isVisible ? 'visibility' : 'visibility_off'}}
      </mat-icon>
      <mat-error *ngIf="forgotPasswordForm.invalid">{{'enterPassword' | translate}}</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label>{{'newPassword' | translate}}</mat-label>
      <input matInput formControlName="retypePassword" spellcheck="false" [type]="isVisible ? 'text' : 'password'">
      <mat-icon class="icon icon--hover" matSuffix (click)="isVisible = !isVisible">
        {{isVisible ? 'visibility' : 'visibility_off'}}
      </mat-icon>
      <mat-error *ngIf="forgotPasswordForm.invalid">{{'enterPassword' | translate}}</mat-error>
    </mat-form-field>
  </div>
</section>
<footer class="login__footer d-flex align-items-center justify-content-end flex-nowrap"
        [class.pointer-disabled]="isFormDisabled">
  <button class="button-login" mat-flat-button color="primary" (click)="submit()">{{'submit' | translate}}</button>
</footer>
