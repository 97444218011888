import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {hexToRgba, setContrastColor} from '../utils/helpers';

interface Data {
  type: 'background' | 'foreground';
  currItem: string;
  opacity?: number;
  filterData: {
    filterKey: string,
    dataSource: {
      color: string,
      [prop: string]: any
    }[],
    defaultColor?: string
  };
}

@Directive({selector: '[appDynamicColor]'})
export class DynamicColorDirective implements OnInit {
  private data: Data;

  constructor(private element: ElementRef, private renderer: Renderer2) {
  }

  @Input() set appDynamicColor(data: Data) {
    if (data) {
      this.data = data;
    }
  }

  ngOnInit() {
    if (this.data.type === 'background') {
      const color = this.setColor(this.data);
      this.renderer.setStyle(this.element.nativeElement, 'backgroundColor', hexToRgba(color, this.data.opacity || 1));
      this.renderer.setStyle(this.element.nativeElement, 'color', hexToRgba(setContrastColor(color), 0.84));
    } else if (this.data.type === 'foreground') {
      this.renderer.setStyle(this.element.nativeElement, 'color', this.setColor(this.data));
    } else {
      console.error('type must be either background or foreground');
    }
  }

  setColor(data) {
    const fData: any = data.filterData;
    const found: any = fData.dataSource.find(item => {
      return item.hasOwnProperty(fData.filterKey) ? item[fData.filterKey] === data.currItem : false;
    });
    return found !== undefined ? found.color : (fData.hasOwnProperty('defaultColor') ? fData.defaultColor : '#000');
  }
}


