import {Theme} from '../shared/models/theme.model';

export class LookupService {
  userRoles = {};
  userRolesRev = {};

  themes: Theme[] = [
    {
      themeClass: 'custom-theme',
      logo: '',
      isLight: true,
      isDark: false,
      primaryLight: '',
      accentLight: '',
      warnLight: ''
    },
    {
      themeClass: 'secondary-theme',
      logo: '',
      isLight: true,
      isDark: false,
      primaryLight: '',
      accentLight: '',
      warnLight: ''
    }
  ];

  constructor() {
    this.load_lookups();
  }

  load_lookups() {
    // if (this.lookup_map_script.userRoles) {
    //   for (const r of this.lookup_map_script.userRoles) {
    //     this.userRoles[r['id']] = r['name'];
    //     this.userRolesRev[r['name']] = r['id'];
    //   }
    // }
  }
}
