<ng-container *ngIf="usernameGroup && usernameGroup.get('username')">
  <div class="login-form" @fadeIn fxLayout="column nowrap" fxLayoutAlign="space-between stretch">
    <header class="login__header" fxLayout="row nowrap" fxLayoutAlign="start space-between">
      <div class="login__header-content px-5">
        <!--<img src="/assets/img/login-logo.png" alt="header-logo"-->
        <!--     width="auto" height="85px" style="vertical-align: middle" class="my-3">-->
        <p class="login__header__title text-center">{{'Willkommen' | translate}}</p>
        <p class="login__header__subtitle text-center">{{'Um sich anzumelden, geben Sie bitte unten Ihre Nutzername ein' | translate}}</p>
      </div>
    </header>
    <section class="login__content form-component"
             fxLayout fxLayoutAlign="center" [formGroup]="usernameGroup" (keyup.enter)="nextStep()">
      <mat-form-field floatLabel="never">
        <i class="material-icons-outlined mr-2" matPrefix>person</i>
        <input type="email" matInput formControlName="username"
               [placeholder]="'nutzername' | translate"
               spellcheck="false" appAutofocus (input)="onInputChange($event)">
        <mat-error *ngIf="usernameGroup.invalid">{{'enterValidUsername' | translate}}</mat-error>
      </mat-form-field>
    </section>
    <footer class="login__footer" fxLayout="row nowrap" fxLayoutAlign="center center">
      <!--<p class="login__link">{{('forgotEmail' | translate)}}?</p>-->
      <button class="button-primary" mat-flat-button
              (click)="nextStep()" color="primary"
              [disabled]="!!usernameGroup.get('username').errors">
        {{'Fortsetzen' | translate}}
      </button>
    </footer>
  </div>
</ng-container>
