import {Injectable} from '@angular/core';
import {LookupService} from '../utils/lookup.service';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {RequestsService} from './requests.service';
import {takeUntil} from 'rxjs/operators';
import {TokenStorageService} from './token-storage.service';

export class User {
  public id: string;
  public username: string;
  public firstName: string;
  public lastName: string;
  public homepage: string;
  public roleFlags: number;
  public profilePhoto: string;
  public coverPhoto: string;

  setData(data) {
    this.username = data.username;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.homepage = data.homepage;
    this.roleFlags = data.role_flags;

    this.coverPhoto = data.cover_photo || '/assets/img/default-user-cover.png';
    this.profilePhoto = data.profile_photo || '/assets/img/default-user-profile.jpg';

    // SET DUMMY PICTURE FOR NON PRODUCTION INSTALLATION
    if (this.profilePhoto && !environment.production) {
      // this.profilePhoto = '/assets/img/profile-image.png';
    }
    if (this.coverPhoto && !environment.production) {
      // this.coverPhoto = '/assets/img/default-user-cover.png';
    }
  }

  setId(id) {
    this.id = id;
  }
}

@Injectable({providedIn: 'root'})
export class LoggedUserService extends User {
  isLogged = false;
  loggedUser = true;
  alreadyLogged = false;
  defaultAvatar = '/assets/img/default-avatar-img.png';
  loginData: { username: string, password: string } = {username: '', password: ''};

  constructor(
    private http: HttpClient,
    private lookup: LookupService,
    private requests: RequestsService,
    private tokenStorage: TokenStorageService,
  ) {
    super();
    this.isLogged = this.checkToken();
  }

  login(resp) {
    if (resp.token) {
      this.tokenStorage.setToken(resp.token);
    }
    this.isLogged = this.checkToken();
    this.setData(resp);
  }

  logout(resp) {
    this.tokenStorage.removeToken();
    this.isLogged = this.checkToken();
    this.loginData = {username: '', password: ''};
  }

  requestLogin(data) {
    return this.http.post(this.requests.getApiUrl('/user/login'), data);
  }

  requestLogout() {
    const options: any = {};
    options.headers = new HttpHeaders({Authorization: this.getToken()});
    return this.http.post(this.requests.getApiUrl('/user/logout'), {}, options);
  }

  requestLoginStatus() {
    const header = {headers: new HttpHeaders({Authorization: this.tokenStorage.getToken()})};
    return this.http.get('/user/login', header);
  }

  checkToken() {
    const token = this.tokenStorage.getToken();
    return !!(token && token.length > 0);
  }

  getToken() {
    return this.tokenStorage.getToken();
  }

  setData(data) {
    if (data.id) {
      super.setId(data.id);
    }
    if (data.id_user) {
      super.setId(data.id_user);
    }

    super.setData(data);
    this.alreadyLogged = data.already_logged;

    this.setRole(data);
  }

  setRole(data: any) {
  }
}
