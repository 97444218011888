<div class="login-form form-component" @fadeIn fxLayout="column nowrap" fxLayoutAlign="space-between stretch">
  <header class="login__header" fxLayout="column nowrap" fxLayoutAlign="space-between center"
          [class.pointer-disabled]="isFormDisabled">
    <div class="mb-2 w-100" fxLayout="row nowrap" fxLayoutAlign="center center">
      <button class="back" (click)="back()" mat-icon-button>
        <mat-icon color="primary">arrow_back</mat-icon>
      </button>
      <p class="login__header__title text-center m-0" fxFlex="100%">{{'Passwort' | translate}}</p>
    </div>
    <div class="login__header-content">
      <p class="login__header__subtitle text-center">{{'Bitte geben Sie Ihr Passwort ein, um fortzufahren' | translate}}</p>
    </div>
  </header>
  <section class="login__content"
           fxLayout fxLayoutAlign="center"
           [class.pointer-disabled]="isFormDisabled"
           [formGroup]="passwordGroup" (keyup.enter)="login()">
    <mat-form-field floatLabel="never">
      <i class="material-icons-outlined mr-2" matPrefix>lock</i>
      <input matInput formControlName="password"
             appAutofocus [placeholder]="'dein passwort' | translate"
             [type]="isVisible ? 'text' : 'password'" spellcheck="false">
      <i class="material-icons-outlined" matSuffix (click)="isVisible = !isVisible">
        {{isVisible ? 'visibility' : 'visibility_off'}}
      </i>
      <mat-error *ngIf="passwordGroup.invalid">{{'enterPassword' | translate}}</mat-error>
    </mat-form-field>
  </section>
  <footer class="login__footer"
          [class.pointer-disabled]="isFormDisabled"
          fxLayout="row nowrap" fxLayoutAlign="center center">
    <!--<p class="login__link" (click)="forgotPassword()">{{('forgotPassword' | translate) + '?'}}</p>-->
    <button class="button-primary" mat-flat-button color="primary"
            (click)="login()" [disabled]="passwordGroup.get('password').errors">
      {{'Fortsetzen' | translate}}
    </button>
  </footer>

  <div class="login__info" fxLayout="column" fxLayoutAlign="center center" *ngIf="isEmailSent" @fadeIn>
    <div class="login__info__body h-100 w-100" fxLayout fxLayoutAlign="center center">
      <button class="back" (click)="back()" mat-icon-button>
        <mat-icon color="primary">arrow_back</mat-icon>
      </button>
      <p class="login__info__text text-center">{{'emailSentCheckEmail' | translate}}</p>
    </div>
  </div>
</div>
