import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable({providedIn: 'root'})
export class ToastService {

  constructor(private snackbar: MatSnackBar, private translate: TranslateService) {
  }

  showInfoToast(msg: string) {
    setTimeout(() => this.snackbar.open(this.translate.instant(msg), null, {duration: 3000}));
  }

  showError() {
    setTimeout(() => this.snackbar.open(this.translate.instant('errorOccurred'), null, {duration: 3000}));
  }
}




