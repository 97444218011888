import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ToastService} from '../../../services/app-toast.service';
import {RequestsService} from '../../../services/requests.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {TranslationService} from '../../../utils/translate.service';
import {MediatorService} from '../../../services/mediator.service';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-login-first-step',
  templateUrl: './login-first-step.component.html',
  styleUrls: ['./login-first-step.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 1, offset: 1})
          ])
        ))
    ])
  ]
})
export class LoginFirstStepComponent implements OnInit, OnDestroy {
  usernameGroup: FormGroup;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public loggedUser: LoggedUserService,
    private translate: TranslationService
  ) {
  }

  get username() {
    return this.usernameGroup.get('username');
  }

  ngOnInit() {
    // setTimeout is added as a solution to "Expression has changed after it was checked" error
    // https://blog.angular-university.io/angular-2-what-is-unidirectional-data-flow-development-mode/
    setTimeout(() => this.mediator.loginStepChanges$.next({}));

    this.usernameGroup = this.fb.group({
      username: this.fb.control(this.loggedUser.loginData.username, [Validators.required])
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onInputChange(event) {
    this.loggedUser.loginData.username = event.target.value;
  }

  nextStep() {
    if (this.usernameGroup.valid) {
      this.router.navigate(['login', 'second-step']);
    } else {
      this.toast.showInfoToast(this.translate.instant('enterUsername'));
    }
  }
}
