<div [style.height.px]="mediator.contentHeight" fxLayout fxLayoutAlign="center center">
  <div class="w-100" style="position: relative; top: calc(-3rem - 36px)">
    <header class="w-100 my-5" fxLayout fxLayoutAlign="center">
      <div class="controls w-75" fxLayout fxLayoutAlign="end center" fxLayoutGap="1.5rem" ngClass.lt-lg="w-100">
        <button mat-stroked-button color="primary"
                [routerLink]="['/enter-code']" style="width: 150px; border-radius: 10px">
          {{'Enter code' | translate | titlecase}}
        </button>
        <button mat-stroked-button color="primary" *ngIf="loggedUser.isLogged"
                (click)="onLogout()" style="width: 150px; border-radius: 10px">
          {{'logout' | translate | titlecase}}
        </button>
      </div>
    </header>
    <app-entry-page>
      <div slot="text">
        <h2 class="title" ngClass.lt-md="text-center">{{'Generieren Sie Ihren persönlichen Code' | translate}}</h2>
        <p ngClass.lt-md="text-center">
          {{'Generierter Code ist Ihr Schlüssel zu einer Konversation. Senden Sie ti an die andere Person, damit diese an' +
        'Ihrem Gespräch teilnehmen kann' | translate}}
        </p>
      </div>
      <mat-form-field slot="input" floatLabel="never" (keyup.enter)="onGenerateCode()">
        <input matInput [formControl]="code"
               spellcheck="false" appAutofocus
               [placeholder]="'dein Code' | translate">
      </mat-form-field>
      <button slot="button"
              class="button-primary"
              mat-flat-button color="primary"
              style="width: 270px" (click)="onGenerateCode()">
        {{'Fortsetzen' | translate}}
      </button>
      <img slot="image" src="/assets/img/landing1.png"
           alt="landing-image" width="100%" height="auto" style="border-radius: 10px">
    </app-entry-page>
  </div>
</div>
