<div *ngIf="loader.loading$ | async" @fade>
  <ng-container #loaderOutlet *ngTemplateOutlet="chosenTemplate"></ng-container>
</div>

<ng-template #defaultPreset>
  <div class="loader-content">
    <mat-spinner [diameter]="35" [strokeWidth]="3"></mat-spinner>
  </div>
</ng-template>

<ng-template #mapPreset>
  <div class="loader-content loader-content--map">
    <!--    <mat-spinner [diameter]="50" [strokeWidth]="4" color="accent"></mat-spinner>-->
  </div>
</ng-template>

<ng-template #cardPreset>
  <div class="loader-content loader-content--card">
    <!--    <mat-spinner [diameter]="35" [strokeWidth]="3"></mat-spinner>-->
  </div>
</ng-template>

<ng-template #tablePreset>
  <div class="loader-content loader-content--table">
    <!--    <mat-spinner [diameter]="35" [strokeWidth]="3" color="warn"></mat-spinner>-->
  </div>
</ng-template>
