import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {ToastService} from '../../../services/app-toast.service';
import {RequestsService} from '../../../services/requests.service';
import {LoggedUserService} from '../../../services/logged-user.service';
import {Subject} from 'rxjs';
import {FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {TranslationService} from '../../../utils/translate.service';
import {MediatorService} from '../../../services/mediator.service';
import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {pipe} from 'rxjs';

@Component({
  selector: 'app-login-second-step',
  templateUrl: './login-second-step.component.html',
  styleUrls: ['./login-second-step.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter',
        animate(200,
          keyframes([
            style({opacity: 0, offset: 0}),
            style({opacity: 1, offset: 1})
          ])
        ))
    ])
  ]
})
export class LoginSecondStepComponent implements OnInit, OnDestroy {
  isVisible = false;
  isEmailSent = false;
  isFormDisabled = false;
  passwordGroup = this.fb.group({password: this.fb.control(null, Validators.required)});

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public loggedUser: LoggedUserService,
    private translate: TranslationService
  ) {
  }

  get password() {
    return this.passwordGroup.get('password');
  }

  ngOnInit() {
    // setTimeout is added as a solution to "Expression has changed after it was checked" error
    // https://blog.angular-university.io/angular-2-what-is-unidirectional-data-flow-development-mode/
    setTimeout(() => this.mediator.loginStepChanges$.next({}));
    this.password.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => this.loggedUser.loginData.password = val);
  }

  ngOnDestroy() {
    this.password.reset();
    this.loggedUser.loginData.password = this.password.value;
    this.mediator.isProgressBarVisible = false;
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  login() {
    if (this.passwordGroup.valid) {
      this.isFormDisabled = true;
      this.mediator.isProgressBarVisible = true;

      let username = this.loggedUser.loginData.username;
      const hasMonkeyA = username.includes('@');
      const splitValues = username.split('@');
      if (!hasMonkeyA || (hasMonkeyA && (splitValues.length > 2 || !splitValues[1].includes('.')))) {
        username = `@!@${username}`;
      }

      this.loggedUser.requestLogin({
        username,
        password: this.loggedUser.loginData.password
      }).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        this.loggedUser.login(resp);
        this.mediator.loginStepChanges$.next({});
        setTimeout(() => this.router.navigate(['generate-code']), 1650);
      }, err => {
        this.isFormDisabled = false;
        this.mediator.isProgressBarVisible = false;
        if (err.hasOwnProperty('error') && err.error.id_error === 20) {
          this.toast.showInfoToast(this.translate.instant('enterValidData'));
        } else {
          this.toast.showInfoToast(this.translate.instant('errorOccurred'));
        }
      });
    } else {
      this.toast.showInfoToast(this.translate.instant('enterPassword'));
    }
  }

  forgotPassword() {
    this.mediator.isProgressBarVisible = true;
    if (this.loggedUser.loginData.username.length > 0) {
      this.requests.post('/user/forgot', {
        username: this.loggedUser.loginData.username
      }).pipe(takeUntil(this.destroy$)).subscribe((resp: any) => {
        this.isEmailSent = true;
        this.mediator.isProgressBarVisible = false;
      }, err => {
        this.mediator.isProgressBarVisible = false;
        this.toast.showError();
      });
    } else {
      this.toast.showInfoToast(this.translate.instant('enterEmail'));
    }
  }

  back() {
    if (this.isEmailSent) {
      this.isEmailSent = false;
    } else {
      this.router.navigate(['login', 'first-step']);
    }
  }
}
