import {Injectable} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';

@Injectable()
export class MatIconRegistryService {

  constructor( private matIconRegistry: MatIconRegistry,
               private domSanitizer: DomSanitizer ) {
    this.matIconRegistry.addSvgIcon(
      'visibility_outline',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/svg/outline-visibility.svg')
    );
  }
}




