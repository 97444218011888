import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ConversationService} from './conversation.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RequestsService} from '../services/requests.service';
import {TranslateService} from '@ngx-translate/core';
import {LoggedUserService} from '../services/logged-user.service';
import {CustomSnackbarComponent} from '../shared/components/snackbars/custom-snackbar/custom-snackbar.component';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {WsService} from '../utils/ws.service';
import {Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {MediaConfigurationComponent} from './media-configuration/media-configuration.component';
import {LoaderService} from '../services/loader.service';

const CONVERSATION_COMMANDS = [
  'iceCandidate',
  'receiveAnOffer',
  'receiversAnswer',
  'removeRemote',
  'peerMessage'
];

@Component({
  selector: 'app-conversation-room',
  templateUrl: './conversation-room.component.html',
  styleUrls: ['./conversation-room.component.scss'],
  providers: [LoaderService]
})
export class ConversationRoomComponent implements OnInit, OnDestroy {

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private ws: WsService,
    private router: Router,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private requests: RequestsService,
    private translate: TranslateService,
    private loggedUser: LoggedUserService,
    public conversation: ConversationService,
  ) {
  }

  ngOnInit(): void {
    this.conversation.requestUserMedia()
      .then(stream => this.conversation.gotStream(stream))
      .catch(err => this.onError(err));

    this.ws.messageEmit$.pipe(
      filter((msg: any) => CONVERSATION_COMMANDS.includes(msg.cmd)),
      takeUntil(this.destroy$)
    ).subscribe((msg: any) => {
      switch (msg.cmd) {
        case 'receiveAnOffer':
          this.conversation.receiveOffer(msg.payload);
          break;
        case 'receiversAnswer':
          this.conversation.receiveAnswer(msg.payload);
          break;
        case 'iceCandidate': {
          this.conversation.receiveIceCandidate(msg.payload);
          break;
        }
        case 'removeRemote':
          this.conversation.closeConnection(false);
          this.conversation.initializeRTCPeerConnection();
          this.conversation.addTracksToConnection(this.conversation.localStream);
          this.conversation.setOffer();
          break;
        case 'peerMessage': {
          this.conversation.handlePeerMessage(msg.payload);
          break;
        }
      }
    });

    this.conversation.connectionStateChange$.pipe(takeUntil(this.destroy$)).subscribe(state => {
      switch (state) {
        case 'connected': {
          this.conversation.startChrono();
          this.conversation.timerId = setInterval(() => {
            this.conversation.setChronometer();
            this.cd.detectChanges();
          }, 1000);
          break;
        }
        case 'closed':
        case 'failed':
        case 'disconnected':
          this.conversation.stopChrono();
          break;
      }
    });

    this.ws.errorRecieve$.pipe(takeUntil(this.destroy$)).subscribe((err: any) => {
      this.snackbar.openFromComponent(CustomSnackbarComponent, {
        duration: 3000,
        panelClass: 'custom-snackbar',
        data: {message: this.translate.instant(err)}
      });
    });

    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();
      this.conversation.closeConnection();
      // e.returnValue = '';
      // delete e.returnValue;
    });
  }

  ngOnDestroy(): void {
    this.conversation.closeConnection();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onCameraToggle() {
     this.conversation.toggleVideoTrack();
  }

  onSettingsChoose() {
    this.dialog.open(MediaConfigurationComponent, {
      width: '430px',
      minHeight: 700,
      autoFocus: false,
      panelClass: 'custom-dialog',
      data: {currentConstraints: this.conversation.currentConstraints},
    }).afterClosed().pipe(takeUntil(this.destroy$)).subscribe((devices: any) => {
      if (devices) {
        this.conversation.updateStream(devices);
      }
    });
  }

  onCallEnd() {
    this.conversation.closeConnection();
    this.router.navigate(['']);
    this.snackbar.open(this.translate.instant('Der Anruf wurde beendet'), null, {duration: 3000});
  }

  onError(err?) {
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      duration: 5000,
      panelClass: 'custom-snackbar',
      data: {message: this.translate.instant(err.message || 'Error occured')},
    });
  }
}
