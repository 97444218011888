import { style } from '@angular/animations';

export const fadeIn = [
  style({ opacity: 0, offset: 0 }),
  style({ opacity: 1, offset: 1 })
];

export const fadeOut = [
  style({ opacity: 1, offset: 0 }),
  style({ opacity: 0, offset: 1 })
];

export const slideDown = [
  style({ transform: 'scaleY(0)', offset: 0 }),
  style({ transform: 'scaleY(1)', offset: 1 })
];

export const slideUp = [
  style({ transform: 'scaleY(1)', offset: 0 }),
  style({ transform: 'scaleY(0)', offset: 1 })
];

export const slideLeft = [
  style({ transform: 'translateX(1)', offset: 0 }),
  style({ transform: 'translateX(0)', offset: 1 })
];

export const slideRight = [
  style({ transform: 'translateX(0)', offset: 0 }),
  style({ transform: 'translateX(1)', offset: 1 })
];
