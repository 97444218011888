<div class="login-wrapper" fxLayout fxLayoutAlign="center center">
  <div class="login" [class.transparent]="mediator.isProgressBarVisible">
    <mat-progress-bar mode="indeterminate"
                      *ngIf="mediator.isProgressBarVisible"
                      style="position: absolute; top: 0; left: 0; z-index: 40">
    </mat-progress-bar>
    <div class="disabled-overlay" @fadeInOpacity *ngIf="mediator.isProgressBarVisible"></div>
    <router-outlet></router-outlet>
  </div>
</div>
