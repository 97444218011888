<div style="position: relative; top: calc(-3rem - 36px)">
  <header class="w-100 my-5" fxLayout fxLayoutAlign="center">
    <div class="controls w-75" fxLayout fxLayoutAlign="end center" fxLayoutGap="1.5rem" ngClass.lt-lg="w-100">
      <button mat-stroked-button color="primary" *ngIf="loggedUser.isLogged"
              [routerLink]="['/generate-code']" style="width: 150px; border-radius: 10px">
        {{'Generate code' | translate | titlecase}}
      </button>
      <button mat-stroked-button color="primary" *ngIf="!loggedUser.isLogged"
              [routerLink]="['/login']" style="width: 150px; border-radius: 10px">
        {{'login' | translate | titlecase}}
      </button>
      <button mat-stroked-button color="primary" *ngIf="loggedUser.isLogged"
              (click)="onLogout()" style="width: 150px; border-radius: 10px">
        {{'logout' | translate | titlecase}}
      </button>
    </div>
  </header>
  <app-entry-page>
    <div slot="text">
      <h2 class="title" ngClass.lt-md="text-center">{{'Bitte geben Sie Ihren Code ein' | translate}}</h2>
      <p ngClass.lt-md="text-center">
        {{'Um den Code zu erhalten, beziehen Sie sich bitte auf das medizinische Material, in dem sich Ihre Lieben befinden.\n' +
      ' Das Zeug liefert Ihnen einen eindeutigen Code, damit Sie ein Gespräch beginnen können.' | translate}}
      </p>
    </div>
    <mat-form-field slot="input" floatLabel="never" (keyup.enter)="onCodeSubmit()">
      <input matInput [formControl]="code"
             spellcheck="false" appAutofocus
             [placeholder]="'dein Code' | translate">
    </mat-form-field>
    <button slot="button"
            class="button-primary"
            mat-flat-button color="primary"
            style="width: 270px" (click)="onCodeSubmit()">
      {{'Fortsetzen' | translate}}
    </button>
    <img slot="image" src="/assets/img/landing2.png"
         alt="landing-image" width="100%" height="auto" style="border-radius: 10px">
  </app-entry-page>
</div>
