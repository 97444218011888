import {ChangeDetectorRef, Injectable} from '@angular/core';
import {WsService} from '../utils/ws.service';
import {CustomSnackbarComponent} from '../shared/components/snackbars/custom-snackbar/custom-snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ConversationService {
  code;
  config;
  communicationId;

  currentConstraints: MediaStreamConstraints = {audio: true, video: {aspectRatio: 16 / 9}};

  localStream: MediaStream;
  remoteStream: MediaStream;

  peerConnection;
  connectionStateChange$: Subject<string> = new Subject<string>();

  videoTrack;
  senderVideoTrack;
  isNegotiating = false;
  shouldDisplayVideoTrack = true;
  isConversationActive = false;
  canEndCall = false;

  start: any = 0;
  end: any = 0;
  diff: any = 0;
  timerId = 0;
  callDuration: any = '0:00:00';

  constructor(
    private ws: WsService,
    private snackbar: MatSnackBar,
    private translate: TranslateService,
  ) {
  }

  getCode(): string {
    return localStorage.getItem('code') || null;
  }

  getCredentials(code) {
    this.ws.sendMessage(JSON.stringify({
      cmd: 'getCredentials',
      payload: {code, id: this.communicationId}
    }));
  }

  setCode(code) {
    this.code = code;
    localStorage.setItem('code', this.code);
  }

  gotStream(stream: MediaStream) {
    if (this.localStream) {
      this.resetStream(this.localStream);
    }
    this.localStream = stream;

    const videoTracks = this.localStream.getVideoTracks();

    if (videoTracks.length) {
      this.videoTrack = videoTracks[0];
    }

    if (this.config.another_offer === null) {
      this.initializeRTCPeerConnection();
      this.addTracksToConnection(this.localStream);
      this.senderVideoTrack = this.peerConnection.getSenders().find(sender => sender.kind === this.videoTrack.kind);
      this.setOffer();
    } else {
      this.receiveOffer(this.config.another_offer);
    }
  }

  initializeRTCPeerConnection() {
    if (!this.peerConnection) {
      this.peerConnection = new RTCPeerConnection(this.config.credentials);

      this.peerConnection.onsignalingstatechange = this.stateCallback.bind(this);
      this.peerConnection.onconnectionstatechange = this.connStateCallback.bind(this);

      this.peerConnection.oniceconnectionstatechange = this.iceStateCallback.bind(this);
      this.peerConnection.onicecandidate = this.onIceCandidate.bind(this);
      this.peerConnection.oniceconnectionstatechange = this.onIceStateChange.bind(this);

      this.peerConnection.onsignalingstatechange = this.signalingStateChangeEvent.bind(this);

      this.peerConnection.ontrack = this.gotRemoteStream.bind(this);
      this.peerConnection.onnegotiationneeded = this.negotiationNeeded.bind(this);
    }
  }

  negotiationNeeded() {
    if (this.isNegotiating) {
      return;
    }

    this.isNegotiating = true;
  }

  stateCallback() {
    const state = this.peerConnection.signalingState || this.peerConnection.readyState;
    console.log(`pc state change callback, state: ${state}`);
  }

  iceStateCallback() {
    if (this.peerConnection) {
      const {iceConnectionState} = this.peerConnection || '';
      console.log(`pc ICE connection state change callback, state: ${iceConnectionState}`);

      switch (this.peerConnection.iceConnectionState) {
        case 'closed':
        case 'failed':
        case 'disconnected':
          console.log('CLOSING');
          this.closeConnection();
          break;
      }
    }
  }

  signalingStateChangeEvent() {
    this.isNegotiating = (this.peerConnection.signalingState !== 'stable');

    switch (this.peerConnection.signalingState) {
      case 'closed':
        console.log('SIGNAL CLOSING');
        this.closeConnection();
        break;
    }
  }

  connStateCallback() {
    const {connectionState} = this.peerConnection || '';
    this.connectionStateChange$.next(connectionState);

    console.log(`peer connection state change callback, state: ${connectionState}`);
  }

  onIceStateChange() {
    if (this.peerConnection) {
      console.log(`------ ${this.peerConnection} ICE state: ${this.peerConnection.iceConnectionState}`);
      console.log('------ ICE state change event: ', event);
    }
  }

  onIceCandidate(event) {
    this.sendIceCandidate(event.candidate);
  }

  sendIceCandidate(candidate) {
    if (!candidate) {
      console.log(`===========>> Empty ice candidate for`);
      return;
    }
    const payload = {
      code: this.code,
      id: this.communicationId,
      candidate: JSON.stringify(candidate)
    };

    this.ws.sendMessage(JSON.stringify({cmd: 'iceCandidate', payload}));
  }

  setOffer() {
    const offerOptions = {
      offerToReceiveAudio: true,
      offerToReceiveVideo: true
    };

    this.peerConnection.createOffer(offerOptions)
      .then(offer => {
        this.peerConnection.setLocalDescription(offer).catch(err => console.log(err));
        this.sendOfferToReceiver(offer);
      });
  }

  sendOfferToReceiver(offer) {
    const msg = JSON.stringify({
      cmd: 'offer',
      payload: {
        offer,
        code: this.code,
        id: this.communicationId
      }
    });
    this.ws.sendMessage(msg);
  }

  receiveOffer(offer) {
    this.initializeRTCPeerConnection();

    const remoteDescription = new RTCSessionDescription(offer);
    this.peerConnection.setRemoteDescription(remoteDescription)
      .then(() => {
        this.addTracksToConnection(this.localStream);
        this.senderVideoTrack = this.peerConnection.getSenders().find(sender => sender.kind === this.videoTrack.kind);
        return this.peerConnection.createAnswer();
      })
      .then(answer => {
        this.peerConnection.setLocalDescription(answer).catch(err => console.log(err));
        this.sendAnswerToSender(answer);
      })
      .catch(err => console.log(err));
  }

  sendAnswerToSender(answer) {
    this.ws.sendMessage(JSON.stringify({
      cmd: 'answer',
      payload: {
        answer,
        code: this.code,
        id: this.communicationId
      }
    }));
  }

  receiveAnswer(answer) {
    // if (!this.peerConnection.remoteDescription) {
    const remoteDescription = new RTCSessionDescription(answer);
    this.peerConnection.setRemoteDescription(remoteDescription).catch(err => console.log(err));
    // }
  }

  receiveIceCandidate(payload) {
    let iceCandidate;
    try {
      iceCandidate = new RTCIceCandidate(JSON.parse(payload));
    } catch (e) {
      console.log('ERROR LOADING PAYLOAD FOR ICE CANDIDATE', e);
    }

    if (iceCandidate) {
      this.peerConnection.addIceCandidate(new RTCIceCandidate(iceCandidate))
        .catch(e => console.log('PC ADDICECANDDATE ERR', e));
    }
  }

  async requestUserMedia(constraints?: MediaStreamConstraints) {
    return await navigator.mediaDevices.getUserMedia(constraints || this.currentConstraints);
  }

  addTracksToConnection(stream) {
    stream.getTracks().forEach(track => this.peerConnection.addTrack(track, stream));
  }

  handlePeerMessage(payload: any) {
    switch (payload.message) {
      case 'remoteHasChanged': {
        if ('stream' in payload) {
          this.addTracksToConnection(payload.stream);
        }
      }
    }
  }

  gotRemoteStream(event) {
    if (this.remoteStream !== event.streams[0]) {
      this.remoteStream = event.streams[0];
    }
  }

  toggleVideoTrack() {
    this.shouldDisplayVideoTrack = !this.shouldDisplayVideoTrack;
    if (!this.shouldDisplayVideoTrack) {
      this.peerConnection.removeTrack(this.senderVideoTrack);
    } else {
      this.peerConnection.addTrack(this.senderVideoTrack);
    }
  }

  whichCamera(track: MediaStreamTrack) {
    return track.getSettings().facingMode;
  }

  updateStream(devices: { audio: string, mic: string, video: string }) {
    this.localStream.getAudioTracks()[0].applyConstraints({deviceId: devices.mic});
    this.localStream.getVideoTracks()[0].applyConstraints({height: 20, deviceId: devices.video});

    this.ws.sendMessage(JSON.stringify({
      cmd: 'peerMessage',
      payload: {
        message: 'remoteHasChanged',
        code: this.code,
        id: this.communicationId,
      }
    }));
  }

  resetStream(stream) {
    stream.getTracks().forEach(track => track.stop());
    stream = null;
  }

  closeConnection(isHangup: boolean = true) {
    if (this.peerConnection) {
      this.peerConnection.ontrack = null;
      this.peerConnection.onremovetrack = null;
      this.peerConnection.onremovestream = null;
      this.peerConnection.onicecandidate = null;
      this.peerConnection.oniceconnectionstatechange = null;
      this.peerConnection.onsignalingstatechange = null;
      this.peerConnection.onicegatheringstatechange = null;
      this.peerConnection.onnegotiationneeded = null;

      if (this.remoteStream) {
        this.resetStream(this.remoteStream);
      }

      if (this.localStream && isHangup) {
        this.resetStream(this.localStream);
      }

      this.peerConnection.close();
      this.peerConnection = null;

      if (isHangup) {
        this.ws.sendMessage(JSON.stringify({
          cmd: 'endCall',
          payload: {code: +this.code, id: this.communicationId}
        }));
      }

      this.stopChrono();
    }
  }

  onError(err?) {
    console.log(err);
    this.snackbar.openFromComponent(CustomSnackbarComponent, {
      duration: 5000,
      panelClass: 'custom-snackbar',
      data: {message: this.translate.instant(err || '')},
    });
  }

  onCreateSessionDescriptionError(error) {
    this.onError(`Failed to create session description: ${error.toString()}`);
  }

  setChronometer() {
    this.end = new Date();
    this.diff = this.end - this.start;
    this.diff = new Date(this.diff);

    let sec = this.diff.getSeconds();
    let min = this.diff.getMinutes();
    const hr = this.diff.getHours() - 1;

    if (min < 10) {
      min = `0${min}`;
    }

    if (sec < 10) {
      sec = `0${sec}`;
    }

    this.callDuration = `${hr}:${min}:${sec}`;
  }

  startChrono() {
    this.canEndCall = true;
    this.isConversationActive = true;
    this.start = new Date();
  }

  resetChrono() {
    this.callDuration = '0:00:00';
    this.start = new Date();
  }

  stopChrono() {
    this.canEndCall = false;
    this.isConversationActive = false;
    clearInterval(this.timerId);
    this.resetChrono();
  }
}
