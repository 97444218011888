import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {ToastService} from '../../services/app-toast.service';
import {MediatorService} from '../../services/mediator.service';
import {RequestsService} from '../../services/requests.service';
import {LoggedUserService} from '../../services/logged-user.service';
import {TranslationService} from '../../utils/translate.service';
import {takeUntil} from 'rxjs/operators';
import {pipe} from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  isVisible = false;
  isFormDisabled = false;
  forgotPasswordForm = this.fb.group({
    oldPassword: this.fb.control(null, Validators.required),
    newPassword: this.fb.control(null, Validators.required)
  });

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private toast: ToastService,
    public mediator: MediatorService,
    private requests: RequestsService,
    public loggedUser: LoggedUserService,
    private translate: TranslationService
  ) { }

  get oldPassword() {
    return this.forgotPasswordForm.get('oldPassword');
  }

  get newPassword() {
    return this.forgotPasswordForm.get('newPassword');
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  submit() {
    if (this.forgotPasswordForm.valid && this.oldPassword.value === this.newPassword.value) {
      this.isFormDisabled = true;
      this.mediator.isProgressBarVisible = true;
      this.requests.post('/user/password/change', {
        old_password: this.oldPassword.value,
        new_password: this.newPassword.value
      }).pipe(takeUntil(this.destroy$)).subscribe(resp => {
        this.mediator.isProgressBarVisible = false;
        this.router.navigate(['login', 'first-step']);
      }, err => {
        this.isFormDisabled = false;
        this.mediator.isProgressBarVisible = false;
        if (err.hasOwnProperty('error') && err.error.id_error === 20) {
          this.toast.showInfoToast(this.translate.instant('enterValidData'));
        } else {
          this.toast.showInfoToast(this.translate.instant('errorOccurred'));
        }
      });
    } else {
      this.toast.showInfoToast(this.translate.instant('passwordsNotMatch'));
    }
  }

  back() {
    this.router.navigate(['login', 'second-step']);
  }
}
