import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {RequestsService} from '../services/requests.service';
import {TranslateService} from '@ngx-translate/core';
import {LoggedUserService} from '../services/logged-user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {ConversationService} from '../conversation-room/conversation.service';
import {WsService} from '../utils/ws.service';

@Component({
  selector: 'app-code-entry',
  templateUrl: './code-entry.component.html',
  styleUrls: ['./code-entry.component.scss']
})
export class CodeEntryComponent implements OnInit, OnDestroy {
  code: FormControl;

  destroy$: Subject<boolean> = new Subject();

  constructor(
    private ws: WsService,
    private router: Router,
    private route: ActivatedRoute,
    private snackbar: MatSnackBar,
    private requests: RequestsService,
    private translate: TranslateService,
    public loggedUser: LoggedUserService,
    public conversation: ConversationService,
  ) {
  }

  ngOnInit(): void {
    this.ws.connect();
    this.code = new FormControl(this.conversation.getCode(), [Validators.required]);

    this.ws.messageEmit$.pipe(
      filter((msg: any) => ['webRTCCredentials', 'communicationId'].includes(msg.cmd)),
      takeUntil(this.destroy$)).subscribe((msg: any) => {
      switch (msg.cmd) {
        case 'communicationId': {
          this.conversation.communicationId = msg.payload;
          break;
        }
        case 'webRTCCredentials': {
          this.conversation.config = msg.payload;
          this.conversation.setCode(this.code.value);
          this.router.navigate(['conversation-room']);
          break;
        }
      }
    });

    this.ws.errorRecieve$.pipe(takeUntil(this.destroy$)).subscribe((err: any) => {
      this.snackbar.open(this.translate.instant(err), null, {duration: 3000});
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onCodeSubmit() {
    if (this.code.invalid) {
      this.snackbar.open(this.translate.instant('Enter code'), null, {duration: 1000});
    } else if (!this.ws.canTransferMessages) {
      this.snackbar.open(this.translate.instant('Service connection error'), null, {duration: 1000});
    } else {
      this.conversation.getCredentials(this.code.value);
    }
  }

  onLogout() {
    this.loggedUser.requestLogout().pipe(takeUntil(this.destroy$))
      .subscribe(res => this.loggedUser.logout(res), error => console.log(error));
  }
}
