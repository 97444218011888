import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';

import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {LoginFirstStepComponent} from './login/login-first-step/login-first-step.component';
import {LoginSecondStepComponent} from './login/login-second-step/login-second-step.component';
import {ChangePasswordDialogComponent} from './change-password-dialog/change-password-dialog.component';

@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    LoginFirstStepComponent,
    LoginSecondStepComponent,
    ChangePasswordDialogComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
  ]
})
export class AuthModule {
}
