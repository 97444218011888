<div class="dialog">
  <div class="dialog__body">
    <p class="dialog__body__text">{{ 'Are you sure?'| translate }}</p>
  </div>
  <div class="dialog__footer">
    <div class="dialog__actions">
      <button class="dialog__actions__button" mat-button tabindex="-1" (click)="dialogRef.close(false)">
        {{ 'Cancel'| translate }}
      </button>
      <button class="dialog__actions__button" mat-flat-button tabindex="-1" color="primary" (click)="dialogRef.close(true)">
        {{ 'Confirm'| translate }}
      </button>
    </div>
  </div>
</div>
